import Tracking from '~/components/Abstracts/Tracking'
import Slice404 from '~/components/Slices/Slice404'

import getErrorPageStaticPropsFunction from '~/data/error-page-data'
import { ErrorPageProps } from '~/data/error-page-data/serializer'

function Page404({ slices, errorPage }: ErrorPageProps) {
  return (
    <>
      {errorPage && <Slice404 {...errorPage} />}
      <Tracking template="404" />
    </>
  )
}

export default Page404

export const getStaticProps = getErrorPageStaticPropsFunction()
