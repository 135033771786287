import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { ImageProps } from '~/components/Abstracts/Image'
import { LinkProps } from '~/components/Abstracts/Link'
import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import { useNavBannerContext } from '~/components/Navigation/Navigation/NavBanner'
import BlurredCta from '~/components/UI/BlurredCta'
import Image from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface Slice404Props {
  className?: string
  background?: ImageProps
  title?: string
  subtitle?: RichTextBlock
  cta?: LinkProps
}

const IMAGE_SIZES = [{ ratio: 24 / 24 }]

function Slice404({
  className,
  background,
  title,
  subtitle,
  cta,
}: Slice404Props) {
  const [withBanner] = useNavBannerContext()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title10Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.RichTextEditorial,
  })

  return (
    <div className={cx(css.Slice404, className, { withBanner })}>
      <Image
        layout="fill"
        objectFit="cover"
        className={css.image}
        sizesFromBreakpoints={IMAGE_SIZES}
        asPlaceholder
        {...background}
      />
      <div className={cx(css.content, gridStyle)}>
        <div className={css.box}>
          {title && <h1 className={cx(css.title, titleStyle)}>{title}</h1>}
          {subtitle && (
            <RichText
              render={subtitle}
              className={cx(css.subtitle, subtitleStyle)}
            />
          )}
          {cta && <BlurredCta className={css.cta} {...cta} />}
        </div>
      </div>
    </div>
  )
}

Slice404.defaultProps = {}

export default Slice404
